import React from 'react'
import PageTrans from '../components/page-trans/page-trans'
import Layout from '../containers/layout'
import SEO from '../components/seo/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div className='four-o-four'>
      <h1>404</h1>
      <p>This page does not exist or has been moved</p>
      <PageTrans fade to='/' className='btn'>Take me Home</PageTrans>
    </div>
  </Layout>
)

export default NotFoundPage
